<template>
  <v-card>
    <div id="PDFORG" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <s-toolbar
            color="primary"
            dark
            label="Imprimir PDF "
            close
            @close="close()"
            print
            @print="PrintPdf()"
          >
          </s-toolbar>
        </div>
        <div id="testHtml" ref="testHtml">
          <table id="table_cabecera">
            <tr>
              <td>
                <img width="200px" src="../../../public/static/logo_sp.png" />
              </td>
              <td style="text-align: center">
                {{ "ORGANIGRAMA DE " + this.selected.PstName }}
              </td>
              <td style="text-align: center">
                <table
                  style="border-collapse: collapse; width: 100%; margin: auto"
                >
                  <tr>
                    <td style="text-align: right">Codigo :</td>
                    <td style="padding-left: 8px">RH-OG-001</td>
                  </tr>
                  <tr>
                    <td style="text-align: right">Version :</td>
                    <td style="padding-left: 8px">01</td>
                  </tr>
                  <tr>
                    <td style="text-align: right">Fecha de Creacion :</td>
                    <td style="padding-left: 8px">06/03/2024</td>
                  </tr>
                  <tr>
                    <td style="text-align: right">Fecha de Revision :</td>
                    <td style="padding-left: 8px">25/03/2024</td>
                  </tr>
                  <tr>
                    <td style="text-align: right">Pagina :</td>
                    <td style="padding-left: 8px">1 de 1</td>
                  </tr>
                  <tr>
                    <td style="text-align: right">Anexos :</td>
                    <td style="padding-left: 8px">00</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        <div class="contenido">
          <div class="contenido" style="no-scroll overflow: hidden !important;">
            <org
              v-if="selected && selected.PstName"
              ref="orgDATA"
              :managerNameView="false"
              :TypePosition="TypePosition"
              :level="0"
              :levels="levels"
              :TypeNivel="TypeNivel"
              :parent="selected"
              :report="false"
            ></org>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
html2canvas;
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Org from "./../../components/Org/Org.vue";
import "jspdf-autotable";
export default {
  components: { Org },
  props: {
    selected: {
      default: null,
      type: Object,
    },
    TypeNivel: {
      type: Number,
      default: 0,
    },
    levels: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      itemsTypePosition: [
        { text: "SOLO FIJOS", value: 1 },
        { text: "TODOS", value: 0 },
      ],
      itemsImpresion: [
        { text: "Nivel 1", value: 1 },
        { text: "TODOS", value: 0 },
      ],
      TypePosition: 0,
    };
  },
  methods: {
    PrintPdf() {
      console.log("PrintPdf");

      this.$nextTick(() => {
        const orgElement = document.getElementById("org");

        if (!orgElement) {
          console.error("El elemento con id='org' no se encontró.");
          return;
        }

        html2canvas(orgElement, {
          scale: 2,
          useCORS: true,
        })
          .then((canvas) => {
            const imgData = canvas.toDataURL("image/png");

            const doc = new jsPDF({
              unit: "mm",
              format: "a4",
              orientation: "landscape",
            });

            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            const imgWidth = pageWidth - 20;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            const yOffset =
              imgHeight < pageHeight ? (pageHeight - imgHeight) / 2 : 10;

            doc.addImage(imgData, "PNG", 10, yOffset, imgWidth, imgHeight);
            doc.save("Organigrama.pdf");
          })
          .catch((error) => {
            console.error("Error al generar el PDF:", error);
          });
      });
    },
    close() {
      this.$emit("closedialogPdf");
    },
  },
};
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  width: 297mm; /* Ancho de documento A4 */
  height: 210mm; /* Alto de documento A4 */
  padding: 0mm 0mm; /* Márgenes internos */
  border: 1px solid #000;
}

#table_cabecera {
  position: relative;
  top: 0;
  width: 290mm; /* Reducir el ancho del encabezado */
  max-width: 300mm; /* Asegurar que no se expanda más de este tamaño */
  margin: 0 auto; /* Centrar el encabezado en la página */
  background-color: #fff;
  border-collapse: collapse;
}

.contenido {
  display: flex;
  margin-top: 100px;
}
</style>

<template>
  <v-row>
    <v-col lg="6">
      <s-crud
        add
        @doubleClick="addE($event)"
        addNoFunction
        @add="addE($event)"
        :refreshTable="refreshTable"
        @rowSelected="rowsSelected($event)"
        :config="configNoLevel"
        search
        title="Cargos No Asignados"
        searchInput
      >
      </s-crud>
    </v-col>

    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      :width="dialogWidth + 'px'"
      :height="dialogHeight + 'px'"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <div style="overflow: auto; max-height: 90vh; max-width: 90vw">
          <organization-pdf
            ref="organizationPdf"
            v-show="dialog"
            :selected="selected"
            :TypeNivel="TypeNivel"
            @closedialogPdf="CloseDialog()"
            :levels="levels"
          ></organization-pdf>
        </div>
      </v-card>
    </v-dialog>

    <v-col lg="6">
      <s-toolbar
        :map="orgv"
        label="Organigrama"
        :save="edit"
        @save="editE()"
        @map="openMap()"
        :remove="remove"
        @removed="removeE()"
      ></s-toolbar>
      <v-card elevation="0">
        <v-col>
          <v-row justify="center">
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-area
                label="Area"
                v-model="area.AreID"
                :text="area.AreName"
              ></s-select-area>
            </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-definition
                label="Nivel Jerárquico"
                :def="1519"
                v-model="hierarchi.PstHierarchicalLevel"
              ></s-select-definition>
            </v-col>
          </v-row>
        </v-col>
        <v-treeview
          item-key="OrgID"
          item-text="PstName"
          item-children="GenPositionChild"
          selectable
          selection-type="independent"
          v-model="active"
          open-all
          :open.sync="open"
          selected-color="accent"
          return-object
          open-on-click
          :items="items"
        ></v-treeview>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ServiceOrganization from "./../../services/HumanResource/OrganizationLevelService";
import Org from "./../../components/Org/Org.vue";
import OrganizationPdf from "./OrganizationPdf.vue";
import SSelectArea from "../../components/Utils/SSelectArea.vue";
import "jspdf-autotable";

export default {
  name: "Organization",
  components: {
    Org,
    OrganizationPdf,
    SSelectArea,
  },
  data() {
    return {
      isDataLoaded: false,
      TypePosition: 1,
      selectedEdit: null,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      open: [],
      dialogWidth: 800,
      dialogHeight: 600,
      area: {
        AreID: null,
        AreName: null,
      },
      hierarchi: {
        PstHierarchicalLevel: 1,
      },
      refreshTable: false,
      edit: false,
      remove: false,
      orgv: false,
      active: [],
      selectedNoLevel: null,
      org: {
        PstID: null,
        PstParentID: null,
      },
      configNoLevel: {
        model: {
          PstName: "string",
          PstID: "ID",
        },
        service: ServiceOrganization,
        headers: [
          { text: "ID", value: "PstID" },
          { text: "Cargo", value: "PstName" },
        ],
      },
      items: [],
      TypeNivel: 0,
      levels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
  },
  computed: {
    selected() {
      if (!this.active.length) {
        return undefined;
      } else this.selectedEdit = { ...this.active[0] };
      this.active = [this.active[this.active.length - 1]];
      this.PstName = this.active[0]?.PstName || ""; // Verificar si active[0] existe

      return this.active[0];
    },
  },
  watch: {
    "area.AreID": "load",
    "hierarchi.PstHierarchicalLevel": "load",
    active() {
      if (this.selected != undefined) {
        this.edit = true;
        this.remove = true;
        this.orgv = true;
      } else {
        this.edit = false;
        this.remove = false;
        this.orgv = false;
      }
    },
  },
  methods: {
    editE() {
      this.$fun.alert("Seguro de Guardar", "question").then((val) => {
        if (val.value) {
          ServiceOrganization.save(
            this.selectedEdit,
            this.$fun.getUserID()
          ).then((r) => {
            this.load();
            this.$fun.alert("Guardado Correctamente", "success");
          });
        }
      });
    },
    addE(item) {
      let parentID = null;
      if (this.selected == null) {
        this.$fun.alert("Debe Seleccionar un cargo en organigrama", "warning");
      } else {
        if (this.selected == undefined) {
          parentID = null;
        } else parentID = this.selected.PstID;

        if (item != null) {
          let obj = {
            PstID: this.selectedNoLevel.PstID,
            PstParentID: parentID,
          };
          ServiceOrganization.save(obj, localStorage.getItem("UsrID")).then(
            () => {
              this.refreshTable = !this.refreshTable;
              this.load();
            }
          );
        }
      }
    },

    removeE() {
      let obj = Object.assign({}, this.selected);
      obj.SecStatus = 0;
      ServiceOrganization.save(obj, localStorage.getItem("UsrID")).then(() => {
        this.refreshTable = !this.refreshTable;
        this.load();
      });
    },
    rowsSelected(items) {
      if (items.length > 0) this.selectedNoLevel = items[0];
      else this.selectedNoLevel = null;
    },
    load() {
      this.active = [];
      this.open = [];
      this.items = [];

      let obj = {
        PstParentID: null,
        PstID: null,
        AreID: this.area.AreID,
        PstHierarchicalLevel: this.hierarchi.PstHierarchicalLevel,
      };
      ServiceOrganization.listLevel(obj).then((r) => {
        this.items = r.data;
        this.items.forEach((element) => {
          this.recursiveTree(element);
        });
        this.active = [];
      });
    },
    recursiveTree(item) {
      this.open.push(item);
      item.PstHierarchicalLevel =
        item.PstHierarchicalLevel > 0
          ? item.PstHierarchicalLevel
          : (item.PstHierarchicalLevel ?? 1) + 1;
      if (item.GenPositionChild.length > 0)
        item.GenPositionChild.forEach((element) => {
          element.PstHierarchicalLevel =
            element.PstHierarchicalLevel > 0
              ? element.PstHierarchicalLevel
              : item.PstHierarchicalLevel + 1;
          this.recursiveTree(element);
        });
    },
    async openMap() {
      if (!this.isDataLoaded) {
        const orgElement = document.getElementById("PDFORG");
        if (orgElement) {
          const { width, height } = orgElement.getBoundingClientRect();
          this.dialogWidth = width + 100;
          this.dialogHeight = height + 100;
        }

        // Cargar los datos del organigrama solo si no están cargados
        await this.$refs.organizationPdf?.$refs.orgDATA.load();
        this.isDataLoaded = true; // Marcar los datos como cargados
      }

      this.dialog = true;
    },
    CloseDialog() {
      this.dialog = false;

      // Recargar los datos solo si es necesario
      this.$nextTick(() => {
        this.isDataLoaded = false; // Permitir que los datos se recarguen si se vuelve a abrir el modal
      });
    },
  },
  created() {
    this.load();
  },
};
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
}
.modal {
  max-width: 100vw;
  max-height: 100vh;
  overflow: auto;
}

.modal-content {
  background-color: #fff;
  width: 297mm; /* Ancho de documento A4 */
  height: 210mm; /* Alto de documento A4 */
  padding: 0mm 0mm; /* Márgenes internos */
  border: 1px solid #000;
}

#table_cabecera {
  position: relative;
  top: 0;
  width: 290mm;
  background-color: #fff;
  border-collapse: collapse;
}

.contenido {
  margin-top: 100px;
}
</style>